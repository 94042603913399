import React from 'react';
import Image from 'next/image';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Layout from 'containers/Layout';
import { posts } from 'containers/blog';
import { format } from 'date-fns';
import GridMui5 from '@mui/material/Grid';
import LinkMui5 from '../../components/atoms/Link';
import TypographyMui5 from '@mui/material/Typography';
import NextLinkMui5 from '../../components/atoms/NextLink';

const StyledContainer = styled(Container)(() => ({
  marginTop: 50,
  marginBottom: 50,
}));

const Blog = () => {
  return (
    <Layout>
      <StyledContainer maxWidth='lg'>
        <GridMui5 container spacing={4}>
          {posts.map((post) => {
            return (
              <GridMui5 item xs={12} sm={6} md={6} lg={4} key={post.slug}>
                <LinkMui5 href={`/blog/${post.slug}`} componant={NextLinkMui5}>
                  <Image
                    src={post.image}
                    height={220}
                    width={370}
                    alt={post.altImageText}
                  />
                  <TypographyMui5 variant='h5' gutterBottom>
                    {post.title}
                  </TypographyMui5>
                </LinkMui5>
                <TypographyMui5 variant='subtitle1' gutterBottom>
                  {post.summary}
                </TypographyMui5>
                <br />
                <LinkMui5 href={`/blog/${post.slug}`} component={NextLinkMui5}>
                  <TypographyMui5 gutterBottom>
                    {format(new Date(post.date), 'MMM do, yyyy')}
                  </TypographyMui5>
                </LinkMui5>
              </GridMui5>
            );
          })}
        </GridMui5>
      </StyledContainer>
    </Layout>
  );
};

export default Blog;
